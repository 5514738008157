/**************************************************************************************************
    FileName  : rootRouter.tsx
    Description
      Root Router

    Update History 
      2024.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for React
import React from "react";

// Import for Pages
// - root
import App from "./App";
import ErrorPage from "pages/ErrorPage";
import MainLayout from "layout/MainLayout";
import authRouter from "./auth/authRouter";
import exerciseRouter from "./exercise/exerciseRouter";
import learningRouter from "./learning/learningRouter";
// - pages
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import UserAgreement from "./UserAgreement";



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                        Routes                                                 //
///////////////////////////////////////////////////////////////////////////////////////////////////
const route = [
  {
    element: <App />,
    errorElement : <ErrorPage />,
    children:[
      {        
        element: <MainLayout/>,        
        children: [
          {
            path : "/",
            element: <Home/>,
          },
          {
            path: "/private-policy",
            element: <PrivacyPolicy/>,
          },
          {
            path: "/user-agreement",
            element: <UserAgreement/>,
          },          
          ...authRouter,
          ...exerciseRouter,
          ...learningRouter,
        ],        
      },
    ]
  }  
];
export default route;