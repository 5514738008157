/**************************************************************************************************
    FileName  : Editor.ts
    Description

    Update History 	  
      2023.07     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';

import {Divider, IconButton} from '@mui/material';
import {parse, TagStartSymbol, TagEndSymbol} from '../SHSParser';
import {utils} from "../Utils";

import SHSTextArea  from './SHSTextArea';
import SHSImage  from './SHSImage';
import SHSYoutube  from './SHSYoutube';
import SHSLink  from './SHSLink';


import { TextStyle, ElementType, ParseElement, SHSElementProps } from "../Define";

import {
	FormatSizeOutlined as FormatSizeOutlinedIcon,
	ImageOutlined as ImageOutlinedIcon,
	YouTube as YouTubeIcon,
	InsertLink as InsertLinkIcon,
	PlaylistAddOutlined as PlaylistAddOutlinedIcon,
	DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
	CodeOutlined as CodeOutlinedIcon,
} from '@mui/icons-material';


import gassert from 'libs/gassert';
import { FileInfo } from 'types';
import { appAlert, appToast } from 'libs/stdlib';


export interface EditorEncodeResult {
	encodedText : string;
	relativeFiles : number[];
}





///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////

const ElementNewText = "new-text";
type TextEditorElementType = ElementType | typeof ElementNewText;

interface TextEditorBuildData {
	type : ElementType;
	elementKey : number;
	data : any;		// eslint-disable-line @typescript-eslint/no-explicit-any
}

interface TextEditorElementProps extends SHSElementProps {
	editorStyle? : unknown;
	type : TextEditorElementType;
	siteUrl : string;

	youtubeApiKey? : string;
	notifyUpdateEditorData : (elementKey : number, elementType : ElementType | typeof ElementNewText, elementData : any)=>void;		// eslint-disable-line @typescript-eslint/no-explicit-any
	notifyObjectLoaded : (elementKey : number, elementType : ElementType)=>void;
	requestDeleteElement : (elementKey : number)=>void;
	requestDeleteTextAreaWithDeleteKey : (elementKey : number, text : string, caretPos : number)=>void;
	notifyUpdateDataInfo : (elementKey : number, newValue : any)=>void;			// eslint-disable-line @typescript-eslint/no-explicit-any
	// for text
	placeholder? : string;	
	requestDeleteTextAreaWithBackspace? : (elementKey : number, text : string)=>void;
	requestInsertYoutubeLinkFromTextArea? : (elementKey : number, type : TextEditorElementType, elementData : any, url : string)=>void;  	// eslint-disable-line @typescript-eslint/no-explicit-any
	requestInsertLinkPreviewFromTextArea? : (elementKey : number, type : TextEditorElementType, elementData : any, url : string)=>void;		// eslint-disable-line @typescript-eslint/no-explicit-any
	requestKeyUp? : (elementKey : number)=>void;
	requestKeyDown? : (elementKey : number)=>void;
	requestLineFeed? : (elementKey : number, firstText : string, secondText : string)=>void;
	notifyLastCursorPos? : (elementKey : number, cursorPos : number, text : string)=>void;
	// for link
	requestLinkMetaTagInfo? : (url : string, cb : (info : any)=>void )=>void;		// eslint-disable-line @typescript-eslint/no-explicit-any
	// for image
	requestUploadImage? : (file : File)=>Promise<any>;		// eslint-disable-line @typescript-eslint/no-explicit-any	
	onProvideImageData? : (imageId : number)=>Promise<FileInfo>;	
}



/*
textEditorData
    type  : element type
    elementKey : element key
    data  : element data

    type : "textarea"
    elementKey : element key
    data : {
        text
    }

    type : "image"
    elementKey : element key
    data : {
        location : "local", "web"
        src : string
    }

    type : "gif"
*/

// properties
// youtubeApiKey : for display youtube title
// isShowToolbar
let _elementKey = 0;
interface SHSTextEditorProps {		
	description? : string;
	requestMentionUserInfo? : (suggestionText : string, callback : (suggestionUserList : any[])=>void )=>void;			// eslint-disable-line @typescript-eslint/no-explicit-any
	requestLinkMetaTagInfo? : (url : string, cb : (info : any)=>void)=>void;		// eslint-disable-line @typescript-eslint/no-explicit-any	
	editorStyle? : any;		// eslint-disable-line @typescript-eslint/no-explicit-any
	requestUploadImage : (file : File)=> Promise<any>;		// eslint-disable-line @typescript-eslint/no-explicit-any
	onProvideImageData? : (imageId : number)=>Promise<FileInfo>;
	siteUrl : string;	
	requestConfirmTmpImages : (fileIds : number[])=>void;
	onFocus? : ()=>void;
	placeholder? : string;
	isShowToolbar? : boolean;
	youtubeApiKey? : string;
	toolbarTop? : string;
}

interface SHSTextEditorState {
	textEditorData : TextEditorBuildData[];	
	updateCount : number;
}

interface LastCursorInfo {
	elementKey : number;
	cursorPos : number;
	text : string;
}




///////////////////////////////////////////////////////////////////////////////////////////////////
//                              SHSTextEditor Implementation                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////

export default class SHSTextEditor extends React.Component<SHSTextEditorProps> {
	currentElementKey : number;
	refImageFiles : any;		// eslint-disable-line @typescript-eslint/no-explicit-any
	refEditor : any;			// eslint-disable-line @typescript-eslint/no-explicit-any
	lastTextAreaCursor : LastCursorInfo = { elementKey : 0, cursorPos :0, text : "" };
	rawText = "test";

    state : SHSTextEditorState = {
		textEditorData : [this.createNewTextData("", false)],		
		updateCount : 0,
    } ;

    // SHSTextEditor event interface
	// props.requestLinkMetaTagInfo
	// props.requestMentionUserInfo
    constructor(props : SHSTextEditorProps) {
		super(props);

        // element -> SHSTextEditor event		        
		this.notifySelectedItem = this.notifySelectedItem.bind(this);
		this.notifyObjectLoaded = this.notifyObjectLoaded.bind(this);
		this.notifyLastCursorPos = this.notifyLastCursorPos.bind(this);
		// element -> SHSTextEditor request
		this.requestDeleteElement = this.requestDeleteElement.bind(this);
		// for update element data
		this.notifyUpdateDataInfo = this.notifyUpdateDataInfo.bind(this);

		// textarea -> SHSTextEditor request
		this.notifyUpdateEditorData = this.notifyUpdateEditorData.bind(this);
		this.requestLineFeed = this.requestLineFeed.bind(this);
		this.requestDeleteTextAreaWithBackspace = this.requestDeleteTextAreaWithBackspace.bind(this);
		this.requestDeleteTextAreaWithDeleteKey = this.requestDeleteTextAreaWithDeleteKey.bind(this);
        this.requestInsertYoutubeLinkFromTextArea = this.requestInsertYoutubeLinkFromTextArea.bind(this);
		this.requestInsertLinkPreviewFromTextArea = this.requestInsertLinkPreviewFromTextArea.bind(this);
		this.requestKeyUp = this.requestKeyUp.bind(this);
		this.requestKeyDown = this.requestKeyDown.bind(this);
		
        // link preview -> SHSTextEditor request
        this.requestLinkMetaTagInfo = this.requestLinkMetaTagInfo.bind(this);

		this.onNewTextElementClick = this.onNewTextElementClick.bind(this);
		this.onDeleteElementClick = this.onDeleteElementClick.bind(this);		
		this.onTextSizeClick = this.onTextSizeClick.bind(this);
		this.onToggleCodingStyleClick = this.onToggleCodingStyleClick.bind(this);
		this.onClickQuotation = this.onClickQuotation.bind(this);
		this.onClickBullet = this.onClickBullet.bind(this);
		this.onClickNumberedList = this.onClickNumberedList.bind(this);
		this.onYoutubeInsertClick = this.onYoutubeInsertClick.bind(this);
		this.onLinkInsertClick = this.onLinkInsertClick.bind(this);
        this.onClickEmptyArea = this.onClickEmptyArea.bind(this);


		this.currentElementKey = 0;

		// for images
		this.onImageInsertClick = this.onImageInsertClick.bind(this);
		this.refImageFiles = React.createRef();
		this.onImagesInsert = this.onImagesInsert.bind(this);

        // focus
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);

		// private functions 
		this._splitTextWithCurrrentPos = this._splitTextWithCurrrentPos.bind(this);

		this.focus = this.focus.bind(this);
		this.refEditor = React.createRef();

        // load data
        if( props.description )
            this.state.textEditorData = this.decodeText(props.description);
	}

    loadData(description : string) {
        this.setState({textEditorData : this.decodeText(description)});
    }



    decodeText(encodedText : string) : TextEditorBuildData[]{
        const _this = this;	// eslint-disable-line @typescript-eslint/no-this-alias
        function build(parsedItems : ParseElement[]) {
            let parsedItem : ParseElement | null = null;            
            const items : TextEditorBuildData[] = [];
            let item : TextEditorBuildData | null = null;			                        

            for( let i=0;  i < parsedItems.length; ++i ) {
                parsedItem = parsedItems[i];				
                item = {
                    type : parsedItem.type,
                    elementKey : _elementKey++,
                    data : {
						...parsedItem,
						isFocus : false
					}
                };
                items.push(item);
            }

            const lastElement = items[items.length-1];
            if( lastElement.type !== ElementType.text  )
                items.push(_this.createNewTextData(""));
            items[items.length-1].data.isFocus = true;
			
            return items;
        }

        const parsedItems = parse(encodedText);        
        return build(parsedItems);

        // return [this.createNewTextData(encodedText, true)]
    }



	updateScreen() {
		this.setState( {updateCount : this.state.updateCount+1} );
	}


	///////////////////////////////////////////////////////////////////////////////////////////////
	// for Text Area
	notifyLastCursorPos(elementKey : number, cursorPos : number, text : string)  {		
		this.lastTextAreaCursor.elementKey = elementKey;
		this.lastTextAreaCursor.cursorPos = cursorPos;
		this.lastTextAreaCursor.text = text;
	}
		

    notifyUpdateEditorData( elementKey : number, elementType : ElementType | typeof ElementNewText, elementData : any ) {		// eslint-disable-line @typescript-eslint/no-explicit-any
        if( elementType === ElementNewText ) {
            this.setState({
                textEditorData : [
                    ...this.state.textEditorData,
                    { type : ElementType.text, elementKey : _elementKey++, data : elementData }
                ]
            });
        } else {
            const newTextEditData = [ ...this.state.textEditorData ];
            const itemIndex = this.getItemIndexWithElementKey(elementKey);
            newTextEditData[itemIndex].data =  elementData;
            this.setState({
                textEditorData : newTextEditData
            });
        }
	}

    notifySelectedItem(elementKey : number) {
		this.setCurrentElementKey(elementKey);
	}

	// check last element type is text or not, if not text element then add a text eleemt at last
	notifyObjectLoaded(elementKey : number, elementType : ElementType) {
		if( elementType === ElementType.text)
			return ;

		const lastElement = this.state.textEditorData[ this.state.textEditorData.length - 1 ];

		if( lastElement.elementKey === elementKey )  {
			this.setState({
				textEditorData : [
					...this.state.textEditorData,
					this.createNewTextData("", true)
				]
			})
		}
	}
	createNewTextData(text : string, isFocus? : boolean, textStyle? : string, listNumber? : number) : TextEditorBuildData {
		if( textStyle === undefined || textStyle === null )
			textStyle = TextStyle.normal;

		//textStyle = "bullet";
		return {
			type : ElementType.text,
			elementKey : _elementKey++,
			data : { text,  isFocus, textStyle, listNumber, cursorPos : 0 }
		};
	}



	///////////////////////////////////////////////////////////////////////////////////////////////
	getElement(elementKey : number) {
		let element;
		for( let i = 0;		i < this.state.textEditorData.length;	++i  ) {
			element =  this.state.textEditorData[i];
			if( elementKey ===  element.elementKey )
				return element;
		}
		return null;
	}
	getElementIndex(elementKey : number) {
		let element;
		for( let i = 0;		i < this.state.textEditorData.length;	++i  ) {
			element =  this.state.textEditorData[i];
			if( elementKey ===  element.elementKey )
				return i;
		}
		return -1;
	}

	getPreviousElement() {
		let prevElement = null;
		let element = null;
		for( let i = 0;		i < this.state.textEditorData.length;	++i  ) {
			prevElement = element;
			element = this.state.textEditorData[i];
			if( element.elementKey ===  this.currentElementKey )
				return prevElement;
		}
		return null;
	}
	getNextElement(elementKey : number) {
		for( let i = 0;		i < this.state.textEditorData.length-1;		++i  ) {
			if( this.state.textEditorData[i].elementKey ===  elementKey )
				return this.state.textEditorData[i+1];
		}
		return null;
	}


	getCurrentElement(){
		return this.getElement(this.currentElementKey);
	}

	getCurrentElementProperty(property : string) {
		return this.getElementProperty(this.currentElementKey, property);
	}
	getElementProperty(elementKey : number, property : string) {
		const element = this.getElement(elementKey);
		if( element === null || element.data === null || element.data === undefined )
			return null;
		return element.data[property];
	}

	updateElementUI(elementKey : number) {
		const element = this.getElement(elementKey);
		if( this.currentElementKey === elementKey) {
			this.setCurrentElementKey(_elementKey);
		}
		gassert(element!==null);
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		element!.elementKey = _elementKey++;
	}

	updateElement(elementKey : number, newValue : any) {	// eslint-disable-line @typescript-eslint/no-explicit-any
		const element = this.updateElementData(elementKey, newValue);
		gassert(element !== undefined);
		if( element === undefined )
			return ;
		

		// 업데이트 하려고하는 엘리먼트가 현재 선택된 엘리먼트라면 현재 선택된 엘리먼트 키또한 업데이트 한다.		
		if( element.elementKey === this.currentElementKey )
			this.setCurrentElementKey(_elementKey);
		element.elementKey = _elementKey++;

		this.updateScreen();
	}
	updateElementData(elementKey : number, newValue : any) {	// eslint-disable-line @typescript-eslint/no-explicit-any
		const element = this.getElement(elementKey);
		if( element === null )
			return ;

		const newData = {
			...element.data,
			...newValue
		};
		element["data"] = newData;
		return element;
	}


	deleteElement(elementKey : number) {
		let deleteElement = null;
		let deleteElementIndex = -1;
		let textInputCount = 0;
		for(let i = 0;	i < this.state.textEditorData.length;	++i ) {
			if( this.state.textEditorData[i].elementKey === elementKey ) {
				deleteElement = this.state.textEditorData[i];
				deleteElementIndex = i;
			}
			if( this.state.textEditorData[i].type === ElementType.text )
				++textInputCount;
		}

		if( deleteElement === null )
			return ;

		// Text Editor has at least one text input
		if( textInputCount === 1 &&  deleteElement.type === ElementType.text  )
			return ;

		this.state.textEditorData.splice(deleteElementIndex, 1);
		this.updateScreen();
	}
	setCurrentElementKey(elementKey : number) {
		if( elementKey === this.currentElementKey )
			return ;
		
		this.currentElementKey = elementKey;
	}


	requestDeleteElement(elementKey : number) {
		if( 1 < this.state.textEditorData.length)
			this.deleteElement(elementKey);
	}


	requestLineFeed(elementKey : number, firstText : string, secondText : string) {
		const newTextEditData = [ ...this.state.textEditorData ];
		const itemIndex = this.getItemIndexWithElementKey(elementKey);

		if( newTextEditData[itemIndex].data )
			newTextEditData[itemIndex].data.text =  firstText;
		else
			newTextEditData[itemIndex].data =  {text : firstText};

		const firstTextStyle = newTextEditData[itemIndex].data.textStyle;
		let secondTextStyle = TextStyle.normal;
		let secondListNumber = undefined;
		if  ( firstTextStyle === TextStyle.bullet ||
			  firstTextStyle === TextStyle.numberedList ||
			  firstTextStyle === TextStyle.quotation
			) {
			// 빈 텍스트 라인에서 bullut 혹은 numbered list 라면 스타일만 normal 로 교체한다.
			if( firstTextStyle === TextStyle.numberedList ) {
				secondListNumber  = newTextEditData[itemIndex].data.listNumber+1;
			}

			if( firstText.length === 0  && secondText.length === 0  ) {
				newTextEditData[itemIndex].data.textStyle = TextStyle.normal;
				newTextEditData[itemIndex].elementKey = _elementKey++;
				this.updateScreen();
				return ;
			}

			// 빈 텍스트가 아니라면 다음 라인도 이전 라인 스타일을 따른다.
			secondTextStyle = firstTextStyle;
		}


		newTextEditData[itemIndex].elementKey = _elementKey++;
		newTextEditData.splice(itemIndex+1, 0, this.createNewTextData(secondText, true, secondTextStyle, secondListNumber) );
		this.setState({
			textEditorData : newTextEditData
		});
	}

    requestDeleteTextAreaWithBackspace(elementKey : number, text : string) {
        const itemIndex = this.getItemIndexWithElementKey(elementKey);
        if( 0 < itemIndex && this.state.textEditorData[itemIndex-1].type === ElementType.text  ) {
            const editorData = [...this.state.textEditorData];
            editorData[itemIndex-1].data.isFocus = true;
            editorData[itemIndex-1].data.cursorPos = editorData[itemIndex-1].data.text.length;
            editorData[itemIndex-1].data.text += text;
            editorData[itemIndex-1].elementKey = _elementKey++;
            editorData.splice(itemIndex, 1);
            this.setState({ textEditorData : editorData});
        } else if ( text.length === 0 ) {
			this.deleteElement(elementKey);
		}
	}

	// Check next element, if next element is text then concat two element
	requestDeleteTextAreaWithDeleteKey(elementKey : number, text : string, caretPos : number) {
		const nextElement = this.getNextElement(elementKey);
		if( nextElement && nextElement.type === ElementType.text) {
			const currentElement = this.getElement(elementKey);
			gassert(currentElement!==null);
			if( currentElement ===null )
				return ;

			currentElement.data.text = text.concat( nextElement.data.text );
			currentElement.data.cursorPos = caretPos;
			currentElement.elementKey = _elementKey++;
			this.deleteElement(nextElement.elementKey);
		}
	}


    updateTextAreaAndInserObject(elementKey : number, type : TextEditorElementType, textareaData : any, newObjectData : any) {	// eslint-disable-line @typescript-eslint/no-explicit-any
        const indexItem = 0 < this.state.textEditorData.length ? this.getItemIndexWithElementKey(this.currentElementKey) : 0;
        const newEditorData = [...this.state.textEditorData];

        if( type === ElementNewText ) {
            newEditorData.splice(indexItem, 0, { type : ElementType.text, elementKey, data : textareaData  });
            newEditorData.splice(indexItem+1, 0, newObjectData);
        }
        else {
             newEditorData[indexItem].elementKey = _elementKey++;
             newEditorData[indexItem].data = textareaData;
             newEditorData.splice(indexItem+1, 0, newObjectData);
        }
        this.setState({textEditorData : newEditorData});
	}
	deleteTextAndInserObject(elementKey : number, type : TextEditorElementType, textareaData : any, newObjectData : any) {	// eslint-disable-line @typescript-eslint/no-explicit-any
        const indexItem = 0 < this.state.textEditorData.length ? this.getItemIndexWithElementKey(this.currentElementKey) : 0;
		const newEditorData = [...this.state.textEditorData];
		newEditorData[indexItem].elementKey = _elementKey++;
		newEditorData.splice(indexItem, 1, newObjectData);
        this.setState({textEditorData : newEditorData});
    }	
    requestInsertYoutubeLinkFromTextArea(elementKey : number, type : TextEditorElementType, elementData : any, url : string) {	// eslint-disable-line @typescript-eslint/no-explicit-any
    	this.deleteTextAndInserObject(elementKey, type, elementData,
    		{ type : ElementType.youtube, elementKey : _elementKey++, data : { youtubeLink : url} }
		);
    }
    requestInsertLinkPreviewFromTextArea(elementKey : number, type : TextEditorElementType, elementData : any, url : string) {	// eslint-disable-line @typescript-eslint/no-explicit-any
    	this.deleteTextAndInserObject(elementKey, type, elementData,
    		{ type : ElementType.link, elementKey : _elementKey++, data : { url } }
		);
	}


	requestKeyUp(elementKey : number) {
		const idxCurrent =  this.getElementIndex(elementKey);
		if( idxCurrent === 0 )
			return ;

		for( let i = idxCurrent-1;	0 <= i;   --i ) {
			if( this.state.textEditorData[i].type === ElementType.text ) {
				this.updateElement( this.state.textEditorData[i].elementKey, {isFocus : true} )
				break;
			}
		}
	}
	requestKeyDown(elementKey : number) {
		const idxCurrent =  this.getElementIndex(elementKey);
		for( let i = idxCurrent+1;	i < this.state.textEditorData.length;   ++i ) {
			if( this.state.textEditorData[i].type === ElementType.text ) {
				this.updateElement( this.state.textEditorData[i].elementKey, {isFocus : true});
				break;
			}
		}
	}


    requestLinkMetaTagInfo(url : string, cb : (info:any)=>void) {	// eslint-disable-line @typescript-eslint/no-explicit-any
        if( this.props.requestLinkMetaTagInfo )
    	   this.props.requestLinkMetaTagInfo(url, cb);
    }






    insertDataCurrentLine(type : ElementType, elementKey : number, elementData : any) {	// eslint-disable-line @typescript-eslint/no-explicit-any
        const indexItem = this.getItemIndexWithElementKey(this.currentElementKey);
        const newEditorData = [...this.state.textEditorData];
		newEditorData.splice(indexItem+1, 0, { type, elementKey, data : elementData  } );
		this.setState({textEditorData : newEditorData});
		console.info("newEditorData", newEditorData);
	}

    getItemIndexWithElementKey(elementKey : number) {
        if( elementKey === -1 )
            return this.state.textEditorData.length;


        let itemIndex;
        for( itemIndex = 0;    itemIndex < this.state.textEditorData.length;    ++itemIndex   ) {
            if( this.state.textEditorData[itemIndex].elementKey === elementKey )
                return itemIndex;
        }
        return this.state.textEditorData.length;

    }

	///////////////////////////////////////////////////////////////////////////////////////////////
	// Toolbar Actions
	async onNewTextElementClick() {
		this.insertDataCurrentLine( ElementType.text, _elementKey++, { text : "", isFocus : true, textStyle : TextStyle.normal});
	}

	async onDeleteElementClick() {
		this.deleteElement(this.currentElementKey);		
	}


	async onYoutubeInsertClick() {
		const result = await appAlert.showInputDialog("Youtube URL");
		if( result.isConfirmed === true ) {
			const youtubeUrl = result.value.trim();
			if( youtubeUrl.length === 0 || utils.isUrlYoutube(youtubeUrl) === false )
				return appToast.error("유튜브 URL이 아닙니다.");
			
			this.insertDataCurrentLine( ElementType.youtube, _elementKey++, { youtubeLink : youtubeUrl});
		}
	}

    onImageInsertClick() {
        this.refImageFiles.current.click();
    }

	async onLinkInsertClick() {
		const result = await appAlert.showInputDialog("Link URL");		
		if( result.isConfirmed === true ) {
			const linkUrl = result.value.trim();
			console.info("linkUrl>>>", linkUrl);
			if( linkUrl.length === 0 || utils.isUrl(linkUrl) === false )
				return appToast.error("URL 형식이 아닙니다.");			
			this.insertDataCurrentLine( ElementType.link, _elementKey++, { url : linkUrl});
		}
	}

	
	_splitTextWithCurrrentPos() {		
		const elementKey = this.lastTextAreaCursor.elementKey; 	
		const cursorPos = this.lastTextAreaCursor.cursorPos;
		const text = this.lastTextAreaCursor.text;		

		const newTextEditData = [ ...this.state.textEditorData ];
		let itemIndex = this.getItemIndexWithElementKey(elementKey);
		const currentTextStyle = newTextEditData[itemIndex].data.textStyle;

		let lineFeedIndexes : number[] = [];
		let lastLineFeedPos = -1;
		let isFindCurrentLine = false;
		let currentLineStart = 0;

		do{
			lastLineFeedPos = text.indexOf("\n", lastLineFeedPos+1);
			if( lastLineFeedPos < 0 )
				break;

			if( isFindCurrentLine ===  false && cursorPos <= lastLineFeedPos ) {				
				if( 0 < lineFeedIndexes.length ) {
					// create prev text element and check find current line
					const prevLinePos = lineFeedIndexes[lineFeedIndexes.length-1];
					const newLineData = this.createNewTextData( text.substring(0, prevLinePos), false, currentTextStyle );
					newTextEditData.splice( itemIndex, 0, newLineData );
					currentLineStart = prevLinePos+1;
					lineFeedIndexes = [];
					itemIndex = itemIndex+1;
				}
				isFindCurrentLine = true;
				lineFeedIndexes.push(lastLineFeedPos);
			} else {
				lineFeedIndexes.push(lastLineFeedPos);
			}
		}while(0 <= lastLineFeedPos);		

		if( lineFeedIndexes.length === 0 ){
			newTextEditData[itemIndex].data.text = text.substring(currentLineStart);
		} else if( isFindCurrentLine === false && 0 < lineFeedIndexes.length) {
			// create prev text data
			const prevLinePos = lineFeedIndexes[lineFeedIndexes.length-1];
			const newLineData = this.createNewTextData( text.substring(0, prevLinePos), false, currentTextStyle );
			newTextEditData.splice( itemIndex, 0, newLineData );

			// update current line
			itemIndex = itemIndex+1;
			currentLineStart = prevLinePos+1;
			newTextEditData[itemIndex].data.text = text.substring(currentLineStart);

		}else {			
			newTextEditData[itemIndex].data.text = text.substring(currentLineStart, lineFeedIndexes[0]);

			// 루프문을 지나면 현재 행의 이전 줄은 이미 prev text 엘리먼트로 처리된다.
			// 그 이후 lineFeedIndexes 에는 현재행을 포함한 나머지 개행 위치가 저장된다.
			// 이에 length가 2 이상이라면 현재 행을 제외한 나머지 행들을 그 다음 엘리먼트로 생성한다.
			const newLineData = this.createNewTextData( text.substring(lineFeedIndexes[0]+1),false, currentTextStyle );
			newTextEditData.splice( itemIndex+1, 0, newLineData );
		}

		this.setState({
			textEditorData : newTextEditData
		});		
	}

	onTextSizeClick() {
		// 만일 마지막의 엘리먼트가 text라면 마지막 커서 위치를 확인하고 
		// 커서가 여러줄인지를 확인한다.		
		// 만일 여러줄이고 커서가 중간에 행에 위치한다면 위, 아래의 텍스트 엘리먼트를 생성하고 텍스트를 분리시킨다.
		// 그리고 현재 커서에만 스타일을 적용한다.
		
		this._splitTextWithCurrrentPos();

		const currentTextStyle = this.getCurrentElementProperty("textStyle");		
		let nextTextStyle;
		switch(currentTextStyle) {			
			case TextStyle.normal :
				nextTextStyle = TextStyle.extraLarge;
				break;
			case TextStyle.extraLarge:
				nextTextStyle = TextStyle.large;
				break;
			case TextStyle.large:
				nextTextStyle = TextStyle.normal;
				break;
			default :
				nextTextStyle = TextStyle.normal;
				break;
		}
		this.updateElement(this.currentElementKey, {textStyle:nextTextStyle, isFocus:true})
	}

	onToggleCodingStyleClick() {
		const currentTextStyle = this.getCurrentElementProperty("textStyle");
		let nextTextStyle;
		if( currentTextStyle !== TextStyle.code )
			nextTextStyle = TextStyle.code;
		else
			nextTextStyle = TextStyle.normal;
		this.updateElement(this.currentElementKey, {textStyle:nextTextStyle, isFocus:true})	
	}

    
    getElementProps(key : number, type : TextEditorElementType, data : any) {	// eslint-disable-line @typescript-eslint/no-explicit-any
        const elementProps : TextEditorElementProps = {
            notifyUpdateEditorData :  this.notifyUpdateEditorData,
			notifyLastCursorPos : this.notifyLastCursorPos,
			notifySelectedItem : this.notifySelectedItem,
			notifyObjectLoaded : this.notifyObjectLoaded,
			requestDeleteElement : this.requestDeleteElement,
			requestDeleteTextAreaWithDeleteKey : this.requestDeleteTextAreaWithDeleteKey,
			notifyUpdateDataInfo : this.notifyUpdateDataInfo,
            editorStyle : this.props.editorStyle,
            data : data,
            elementKey : key,
            type,
			siteUrl : this.props.siteUrl
        };

        if( type === ElementType.text || type === ElementNewText ) {
            elementProps.requestDeleteTextAreaWithBackspace = this.requestDeleteTextAreaWithBackspace;
            elementProps.requestInsertYoutubeLinkFromTextArea = this.requestInsertYoutubeLinkFromTextArea;
			elementProps.requestInsertLinkPreviewFromTextArea = this.requestInsertLinkPreviewFromTextArea;
			elementProps.requestKeyUp = this.requestKeyUp;
			elementProps.requestKeyDown = this.requestKeyDown;
			elementProps.requestLineFeed = this.requestLineFeed;			
        } else if( type === ElementType.link ) {
        	elementProps.requestLinkMetaTagInfo = this.requestLinkMetaTagInfo;
        } else if( type === ElementType.image ) {
        	elementProps.requestUploadImage = this.props.requestUploadImage;
            elementProps.onProvideImageData = this.props.onProvideImageData;            
        }

        return elementProps;
    }


    

	onClickBullet() {
		const currentTextStyle = this.getCurrentElementProperty("textStyle");
		let nextTextStyle;
		if( currentTextStyle !== TextStyle.bullet )
			nextTextStyle = TextStyle.bullet;
		else
			nextTextStyle = TextStyle.normal;
		this.updateElement(this.currentElementKey, {textStyle:nextTextStyle, isFocus:true})
	}

	onClickQuotation() {
		const currentTextStyle = this.getCurrentElementProperty("textStyle");
		let nextTextStyle;
		if( currentTextStyle !== TextStyle.quotation )
			nextTextStyle = TextStyle.quotation;
		else
			nextTextStyle = TextStyle.normal;
		this.updateElement(this.currentElementKey, {textStyle:nextTextStyle, isFocus:true})
	}


	onClickNumberedList() {
		const currentTextStyle = this.getCurrentElementProperty("textStyle");
		let nextTextStyle;
		let listNumber = 1;
		if( currentTextStyle !== TextStyle.numberedList )  {
			nextTextStyle = TextStyle.numberedList;
			const prevElement = this.getPreviousElement();
			if( prevElement  && prevElement.type === ElementType.text &&  prevElement.data.textStyle === TextStyle.numberedList ) {
				listNumber = prevElement.data.listNumber + 1;
			}
		}
		else
			nextTextStyle = TextStyle.normal;

		this.updateElement(this.currentElementKey, {textStyle:nextTextStyle, isFocus:true, listNumber})
	}

	async onImagesInsert(event : any) {		// eslint-disable-line @typescript-eslint/no-explicit-any
		const files = event.target.files;
		if ( !(FileReader && files && files.length) )
			return ;

		const indexItem = this.getItemIndexWithElementKey(this.currentElementKey);
		const newEditorData = [...this.state.textEditorData];

		// 현재라인에서 거꾸로 입력해야 입력한 순서대로 나오게 된다.
		for( let i = files.length-1;		0 <= i;		--i)  {
			newEditorData.splice(
				indexItem+1, 0,
				{ type: ElementType.image, elementKey :  _elementKey++, data : { file : files[i] } }
			);
		}

		this.setState({
			textEditorData : newEditorData
		});
	}

	notifyUpdateDataInfo(elementKey : number, newValue : any) {		// eslint-disable-line @typescript-eslint/no-explicit-any
		this.updateElementData(elementKey, newValue);
	}


	public focus() {
		// this.refEditor.current.focus();
		console.info("textEditorData>>", this.state.textEditorData);
		const newEditorData = this.state.textEditorData;
		newEditorData[0].data.isFocus = true;
		this.setState({
			textEditorData : newEditorData
		});
		this.updateScreen();
	}

	public async encodeAndConfirmTmpImage() : Promise<EditorEncodeResult> {
		function encodeElement(json : any) {	// eslint-disable-line @typescript-eslint/no-explicit-any
			return TagStartSymbol + JSON.stringify(json)  + TagEndSymbol;
		}


		const textEditorData = this.state.textEditorData;
		let element;    

		let encodedText = "";
        const relativeFiles = [];
		const confileTmpImageFiles = [];
		for( let i = 0;		i < textEditorData.length;	++i ) {
			element = textEditorData[i];
			if( element.type === ElementType.text ) {
				switch(element.data.textStyle) {
					case TextStyle.normal :
						encodedText += element.data.text;
						break;
					case TextStyle.large :
					case TextStyle.extraLarge :
					case TextStyle.code:
					case TextStyle.quotation :					
					case TextStyle.bullet :
						encodedText += encodeElement({ type : element.type,  textStyle : element.data.textStyle, text : element.data.text });
						break;
					case TextStyle.numberedList :
						encodedText += encodeElement({ type : element.type,  textStyle : element.data.textStyle, text : element.data.text, listNumber : element.data.listNumber  });
						break;
					default :
						encodedText += element.data.text;
						break;
				}
			} else if (element.type === ElementType.image) {
				console.info("image data>>>", element.data);
				const fileInfo : FileInfo = element.data;
                relativeFiles.push(element.data.id);
				encodedText += encodeElement({
                    type : element.type,
                    id : fileInfo.id,
					fileName : fileInfo.name										
				});
				if( fileInfo.state === "tmp" )
					confileTmpImageFiles.push(fileInfo.id);				
				
			} else if (element.type === ElementType.link) {
				encodedText += encodeElement({
                    type : element.type,
                    preview : element.data.preview,
					url : element.data.url,
				});
			} else if (element.type === ElementType.youtube) {
                // 기존 데이터는 id로 저장하기에 기존 데이터의 호환을 위해 id로도 저장한다.
				encodedText += encodeElement({
					type : element.type,
                    id : element.data.youtubeId,
					youtubeLink : element.data.youtubeLink,			
					youtubeType : element.data.youtubeType
				});
			}
		} // end for (textEditorData)

		this.props.requestConfirmTmpImages(confileTmpImageFiles);
		return {encodedText, relativeFiles};
	
	}

    test() {
        alert("teest");
    }

    onClickEmptyArea() {
        // focus last element and last position
        const element = this.state.textEditorData[this.state.textEditorData.length-1];
        element.data = {...element.data, isFocus : true, cursorPos : element.data.text.length };
        element.elementKey = _elementKey++;
        this.updateScreen();
    }

    onBlur() {
		// Empty
    }

    onFocus() {
		console.log("onFocus.....");
        if( this.props.onFocus )
            this.props.onFocus();
    }



    render() {
		// console.info("this.state.textEditorData>>>", this.state.textEditorData);

        return (
            <div className="spacehub-text-editor" tabIndex={0} onBlur={this.onBlur} ref={this.refEditor}>
				<div className="spacehub-text-body-container">

					<div className="toolbar-panel"
						style={{
							display: this.props.isShowToolbar ? "flex" : "none",
							backgroundColor : this.props.editorStyle?.toolbarBacground,
							top : this.props.toolbarTop
						}}
					>
						<IconButton className="toolbar-icon" aria-label="add-new-text" onClick={this.onNewTextElementClick} >
							<PlaylistAddOutlinedIcon/>
						</IconButton>
						<IconButton className="toolbar-icon" aria-label="delete-element" onClick={this.onDeleteElementClick} >
							<DeleteOutlineOutlinedIcon/>
						</IconButton>

						<Divider orientation='vertical'/>

						<IconButton className="toolbar-icon" aria-label="add-image" onClick={this.onYoutubeInsertClick} >
							<YouTubeIcon/>
						</IconButton>
						<IconButton className="toolbar-icon" aria-label="add-image" onClick={this.onImageInsertClick} >
							<ImageOutlinedIcon/>
						</IconButton>
						<IconButton className="toolbar-icon" aria-label="add-image" onClick={this.onLinkInsertClick} >
							<InsertLinkIcon/>
						</IconButton>
						

						<Divider orientation='vertical'/>

						<IconButton className="toolbar-icon" aria-label="text-size" onClick={this.onTextSizeClick}>
							<FormatSizeOutlinedIcon/>
						</IconButton>
						<IconButton className="toolbar-icon" aria-label="text-size" onClick={this.onToggleCodingStyleClick}>
							<CodeOutlinedIcon/>
						</IconButton>
					</div>

					<div className="spacehub-text-body" >
    					{
    						this.state.textEditorData.map((data, index)=>{
    							const elementProps = this.getElementProps(data.elementKey, data.type, data.data);
    							switch( data.type ) {
    								case ElementType.text : {
                                        if( index === 0 && this.state.textEditorData.length === 1)
                                            elementProps.placeholder = this.props.placeholder;
    									const component =  React.createElement(SHSTextArea, elementProps as any);	// eslint-disable-line @typescript-eslint/no-explicit-any
    									return <React.Fragment key={data.elementKey}>{component}</React.Fragment>
    								}
									
    								case ElementType.image :  {
										// console.info("elementProps>>>>############", elementProps);
    									const component =  React.createElement(SHSImage, elementProps as any);		// eslint-disable-line @typescript-eslint/no-explicit-any
    									return <React.Fragment key={data.elementKey}>{component}</React.Fragment>
    								}
    								case ElementType.youtube : {
    									elementProps.youtubeApiKey = this.props.youtubeApiKey;
    									const component =  React.createElement(SHSYoutube, elementProps as any);	// eslint-disable-line @typescript-eslint/no-explicit-any
    									return <div key={data.elementKey}>{component}</div>
    								}
    								case ElementType.link : {
    									const component =  React.createElement(SHSLink, elementProps as any);	// eslint-disable-line @typescript-eslint/no-explicit-any
    									return <div key={data.elementKey}>{component}</div>
    								}

    								default: {
    									return <div key="-1"></div>;
    								}
    							}

    						})
    					}
                        <div className="empty-area" onClick={this.onClickEmptyArea}/>
					</div>
				</div>

			

				<input
					ref={this.refImageFiles}
					type="file"
					onChange={this.onImagesInsert}
					style={{display:"none"}}
					accept="image/*"
					multiple
					>
				</input>

            </div>
        );
    }
}
