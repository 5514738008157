/**************************************************************************************************
    FileName  : types/index.ts
    Description

    Update History       
      2023.10     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                           Imports                                             //
///////////////////////////////////////////////////////////////////////////////////////////////////
import { IRootState } from "redux/reducer";



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                            Types                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
export enum BuildMode {
	production = "production",
	development = "development"
}


export interface DefaultErrorType {
	message : string;
}

export interface ServerListData<T> {
	isLast : boolean;
	lastItemIdInList : number;
	list : T[];
}

export enum PostState {
	open = "open",
	archived = "archived",
}

export interface ExerciseData {
	id : number;
	userId : number;
	title : string;
	description : string;	
	relativeFiles : number[];	
	state : PostState;
	childCount : number;
}

export interface ExerciseDetailData extends ExerciseData {
	learningList : LearningData[];
}

export enum LearningCategory {
	fundamental = 'fundamental', 
	practice = "practice",
	tool = 'tool',
	tip = 'tip',
	example = "example",
	none = "none"
}



export interface LearningData {
	id : number;
	userId : number;
	category : LearningCategory,
	title : string;
	description : string;	
	relativeFiles : number[];	
	state : PostState;
	viewCount : number;
	childCount : number;
}

      
    

export interface LearningDetailData extends LearningData {
	exerciseList : ExerciseData[];
}


export interface DefaultList<T> {
    isLast : boolean;
    lastItemIdInList : number;
    list : T[]
}




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                           Exports                                             //
///////////////////////////////////////////////////////////////////////////////////////////////////
export type { IRootState };
export * from "./user";
export * from "./common";
export * from "./post";


