// gassert assert
function gassert(condition: boolean) {
    if (condition === true) return;

    const errorPanel = document.createElement('div');
    errorPanel.id = "hdAssertErrorLayer";
    errorPanel.innerText = "Assert Failed.\nShow console for detail";
    errorPanel.style.width = "100vw";
    errorPanel.style.height = "100vh";
    errorPanel.style.background = "rgba(0, 0, 0, 0.8)";
    errorPanel.style.position = "fixed";
    errorPanel.style.left = "0";
    errorPanel.style.top = "0";
    errorPanel.style.zIndex = "9999999";
    errorPanel.style.color = "white";
    errorPanel.style.fontSize = "64px";
    errorPanel.style.textAlign = "center";
    errorPanel.style.paddingTop = "100px";
    window.document.body.appendChild(errorPanel);

    const e = new Error();
    console.error(e);
}

export default gassert;