/**************************************************************************************************
    FileName  : types.tsx
    Description

    Update History       
      2023.10     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React from "react";
import ReactDOM from 'react-dom/client';
// for redux 
import { Provider as ReduxProvider } from 'react-redux';
import store from './redux/store';


import {
  createBrowserRouter,
  RouterProvider  
} from "react-router-dom";
import './index.scss';
import rootRouter from "./routes/rootRouter";
// for customizing  theme
import { ThemeProvider } from '@mui/material/styles';
import theme from "./theme";
import ErrorBoundary from "./ErrorBourndary";
import { appToast } from "libs/stdlib";
import AppError, {ServerResponseError} from "libs/error";
// import { ErrorBoundary } from "react-error-boundary";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Constants                                                    //
///////////////////////////////////////////////////////////////////////////////////////////////////
const router = createBrowserRouter(rootRouter, {basename : process.env.PUBLIC_URL});
const root = ReactDOM.createRoot(
  document.getElementById('bgprogramming') as HTMLElement
);


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Main                                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
root.render(  
    <ErrorBoundary fallback={<div>Something went wrong</div>} >
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
            <RouterProvider router={router}/>            
        </ThemeProvider>
      </ReduxProvider>
    </ErrorBoundary>
);


// Handling for Unhandled Error
// 함수등에서 throw 가 발생하여 unhandledrejection 이 발생했을 경우 처리
window.addEventListener("unhandledrejection", (e : PromiseRejectionEvent)=>{      

    console.log("unhandledrejection@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@2");

    if( e.reason instanceof AppError) {    
      let msg = e.reason.getMessage();
      const detailMessage = e.reason.getDetailMessage();
      if( 0 < detailMessage.length ) {
        msg += "\n" + detailMessage;
      }
      appToast.error(msg);
    }
    else if( e.reason instanceof ServerResponseError ) {
      let msg = e.reason.getMessage();
      const detailMessage = e.reason.getDetailMessage();
      if( detailMessage && 0 < detailMessage.length ) {
        msg += "\n" + detailMessage;
      }
      appToast.error(msg);
    }
});


window.onerror = function (message, source, lineno, colno, error) {
	console.error("Error occurred: ", message, " at ", source, ":", lineno, ":", colno);
	if (error) {
		console.error("Stack trace: ", error.stack);
	}
};