/**************************************************************************************************
    FileName  : APIBaseRequester.tsx
    Description

    Update History 
      2024.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import axios, {AxiosInstance, AxiosResponse} from 'axios';
import { ServerResponseError } from 'libs/error';
import { HttpMethod, APICallbackSuccess, APICallbackError } from "./apiTypes";
import { DefaultErrorType } from "types";
import appToast from "components/AppToast";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////
export interface ServerDefinedError {
    code: number,
    detailMessage: string,
    msg: string,
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                   Class Implementation                                        //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default class APIBaseRequester {
    ///////////////////////////////////////////////////////////////////////////
    //                              Member Variables                         //
    ///////////////////////////////////////////////////////////////////////////
    private _axiosInst : AxiosInstance;
    
    ///////////////////////////////////////////////////////////////////////////
    //                              Constructor                              //
    ///////////////////////////////////////////////////////////////////////////
    public constructor(instance : AxiosInstance) {
        this._axiosInst = instance;
        this._updateAxiosInstanceForAPI = this._updateAxiosInstanceForAPI.bind(this);
        this.get = this.get.bind(this);
        this.post = this.post.bind(this);
        this.put = this.put.bind(this);
        this.delete = this.delete.bind(this);
    }
        
    ///////////////////////////////////////////////////////////////////////////
    //                      Public Interface Functons                        //
    ///////////////////////////////////////////////////////////////////////////
    public _updateAxiosInstanceForAPI(instance : AxiosInstance) {
        this._axiosInst = instance;
    }

    ///////////////////////////////////////////////////////////////////////////
    // Default get, post, put, delete
    public async get<T>(url: string): Promise<T> {        
        return await this.request<T>( HttpMethod.get, url);
    }
    public async post<T>(url: string, reqData?: unknown): Promise<T> {
        return await this.request<T>( HttpMethod.post, url, reqData);
    }
    public async put<T>(url: string, reqData?: unknown): Promise<T> {
        return await this.request<T>( HttpMethod.put, url, reqData);
    }
    public async delete<T>(url: string): Promise<T> {
        return await this.request<T>( HttpMethod.delete, url );
    }


    ///////////////////////////////////////////////////////////////////////////
    //                            Base Requester                             //
    ///////////////////////////////////////////////////////////////////////////
    public async request<T>(method: HttpMethod, url: string, reqData?: unknown): Promise<T> {
        let axiosResponse: AxiosResponse;

        if (process.env.REACT_APP_BUILD_MODE !== 'production') {            
            console.log(`\n👉     call - %c${method.toUpperCase()} %c${url}`, 'color: #9772FB; font-weight: bold;', '');
            console.log(`      token - ${this._axiosInst.defaults.headers.common['Authorization']}`);
            console.info(`       body - `, reqData);
            console.log('\n')
        }

        try {
            switch (method) {
                case HttpMethod.get:
                    axiosResponse = await this._axiosInst.get(url);
                    break;

                case HttpMethod.post:
                    axiosResponse = await this._axiosInst.post(url, reqData);
                    break;

                case HttpMethod.put:
                    axiosResponse = await this._axiosInst.put(url, reqData);
                    break;

                case HttpMethod.delete:
                    axiosResponse = await this._axiosInst.delete(url);
                    break;
            }

            this._checkHttpBodyIsHtmlDocument(axiosResponse.data);

            if( axiosResponse.data.bgData ) 
                return axiosResponse.data.bgData.item || axiosResponse.data.bgData.items ;
            else 
                return axiosResponse.data;
        } catch (error : unknown) {            

            // eslint-disable-next-line @typescript-eslint/no-explicit-any   
            let err: any = error;
            if (axios.isAxiosError(error)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const responseData: any = error.response?.data;                

                if (responseData !== undefined && responseData !== null) {
                    let message = '';

                    if (this._isServerDefinedError(responseData)) {
                        const serverDefinedErr: ServerDefinedError = responseData;
                        
                        // 2000 = 세션 만료
                        if (serverDefinedErr.code === 2000) {
                            // 핫 리로드에 의해 아래 조건이 실행되면 개발 환경에서 계속 로그아웃 처리가 되기 때문에 production에서만 실행하도록 함.
                            if (process.env.REACT_APP_BUILD_MODE === 'production') {
                                // source.cancel();
                                window.location.href = '/';
                                throw Error('로그인이 필요합니다.');
                            } else {
                                throw Error('이 에러 무시하세요 (개발 환경에서만 발생)');
                            }
                        } else {
                            message = `${serverDefinedErr.code}, ${serverDefinedErr.msg}`;
                        }
                        
                    } else {
                        message = err.message;
                    }
                    err = {
                        message,
                        ...responseData
                    };
                }

            } else {
                console.log('💀 unexpected error');
            }
                        
            if( err.code !== undefined && err.message !== undefined  && err.msg !== undefined ) {                                
                throw new ServerResponseError(err);
            } else {
                throw err;
            }
        } // end catch (error : unknown)
    }




    ///////////////////////////////////////////////////////////////////////////
    // get, post, put, delete with callback function
    public async getWithCallback<T>(url: string, fnCallbackSuccess? : APICallbackSuccess<T>, errorMessage? : string | null) : Promise<void> {
        await this._requestWithCallback<T>( HttpMethod.get, url, null, fnCallbackSuccess, errorMessage);
    }
    public async postWithCallback<T>(url: string, reqData?: unknown, fnCallbackSuccess? : APICallbackSuccess<T>, errorMessage? : string | null) : Promise<void> {
        await this._requestWithCallback<T>( HttpMethod.post, url, reqData, fnCallbackSuccess, errorMessage);
    }
    public async putWithCallback<T>(url: string, reqData?: unknown, fnCallbackSuccess? : APICallbackSuccess<T>, errorMessage? : string | null) : Promise<void> {
        await this._requestWithCallback<T>( HttpMethod.put, url, reqData, fnCallbackSuccess, errorMessage);
    }
    public async deleteWithCallback<T>(url: string, fnCallbackSuccess? : APICallbackSuccess<T>, errorMessage? : string | null) : Promise<void> {
        await this._requestWithCallback<T>( HttpMethod.delete, url, null, fnCallbackSuccess, errorMessage );
    }
    private async _requestWithCallback<T>( 
        method : HttpMethod, 
        url : string, 
        reqData : unknown, 
        fnCallbackSuccess : APICallbackSuccess<T> | undefined | null,
        error : APICallbackError | string | undefined | null
    ) : Promise<void> {
        try {
            const res =  await this.request<T>(method, url, reqData)
            if( fnCallbackSuccess ) {            
                fnCallbackSuccess(res);                
            }            
        } catch(e:unknown){ 
            if( error === undefined || typeof error === "string" ) {                
                let errorMessage;
                if( typeof error === "string" ) {
                    errorMessage = error;
                } else if ( e instanceof ServerResponseError ) {                    
                    const serverError = e as ServerResponseError;                    
                    errorMessage = `${serverError.getCode()} : ${serverError.getMessage()}`;
                    if( 0 < serverError.getDetailMessage().length ) {
                        errorMessage += "\n" + serverError.getDetailMessage();
                    }                                
                } else {
                    errorMessage = (e as DefaultErrorType).message;
                }
                                
                appToast.error(errorMessage);                
            } else if( error === null ){
                return ; // Do nothing
            }else {
                error(e);         
            }            
        }
    }



    ///////////////////////////////////////////////////////////////////////////
    //                     Private Interface functons                        //
    ///////////////////////////////////////////////////////////////////////////
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _checkHttpBodyIsHtmlDocument(body: any) {
        if (typeof body === 'string' && body.includes('<html') === true) {
            throw Error('404 Not Found');
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _isServerDefinedError(data: any): boolean {
        return data !== undefined && data !== null 
            && typeof data.code === 'number'
            && typeof data.msg === 'string'
            && typeof data.detailMessage === 'string'
    }
    
}
