/**************************************************************************************************
    FileName  : error.ts
    Description

    Update History       
      2023.10     BGKim     Create
**************************************************************************************************/

import gassert from "./gassert";

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                    Public Type Definitions                                    //
///////////////////////////////////////////////////////////////////////////////////////////////////
export enum ErrorCode {
    noAuth = 1,
    invalidPathParam = 2,
    unknown
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                               Class Implementation                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default class AppError extends Error {
    private _detailMessage = "";
    private _errorCode: ErrorCode;
  
    public constructor(code: ErrorCode, detailMessage?: string) {
        super(AppError._getMessage(code));
        this._errorCode = code;
        if (detailMessage)
            this._detailMessage = detailMessage;
    }
  
    public getDetailMessage(): string {
      return this._detailMessage;
    }
  
    private static  _getMessage(code : number): string {
        switch (code) {
          case ErrorCode.noAuth: return "User has no auth.";
          case ErrorCode.invalidPathParam: return "Invaild path parameters";
          default:
              gassert(false);
        }
        return "";
    } 

    public getMessage(): string {
      return AppError._getMessage(this._errorCode);
    } 
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                               ServerResponse Error                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////

interface ServerResponseData {
    code : number,
    message : string,
    msg : string;
    detailMessage : string;
}

export class ServerResponseError extends Error {    
    private _code : number;    
    private _msg : string;
    private _detailMessage : string;

    constructor( err : ServerResponseData) {
        super(err.message);
        console.info("ServerResponseError>>>>", err);
        this._code = err.code;
        this._msg = err.msg;
        this._detailMessage = err.message;
    }

    public getMessage() : string {
        return this._msg;
    }

    public getDetailMessage() : string {
        return this._detailMessage;
    }

    public getCode() : number {
        return this._code;        
    }

}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                      Public Constsnts                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
export const error = {
    newInstanceNotAdmin : ()=>{ return new AppError(ErrorCode.noAuth, "I'm watching you"); },
    newInstanceInvalidPathParam : ()=>{ return new AppError(ErrorCode.invalidPathParam, "Invalid path parameters"); },
    newInstanceUnknown : ()=>{ return new AppError(ErrorCode.unknown, "Unknown Error"); }
}
