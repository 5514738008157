/**************************************************************************************************
    FileName  : SpacePostWritePage.tsx
    Description

    Update History 
      2024.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React from "react";
// Import for Css
import "./LearningWritePage.scss";
// Import for Types
import { LearningCategory } from "types";
// Import for React
import { useState, useRef } from "react";
// Import for Material UI
import {
    Stack, Button, TextField, Typography
} from "@mui/material";
// Import for Project Base
import { api, appToast, appNavigator } from "libs/stdlib";
// Import for Project Modules
import SHSTextEditor, {TextEditorRefType} from "components/SHSText/SHSTextEditor";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  SpacePostWritePage Implementation                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
function ExerciseWritePage() {
    /////////////////////////////////////////////////////////////////////////////
    // const
    const [category, setCategory] = React.useState<LearningCategory>(LearningCategory.none);
    const [title, setTitle] = useState<string>("");
    const refTextEditor : TextEditorRefType = useRef(null);

        
    /////////////////////////////////////////////////////////////////////////////
    // API
    async function api_writeLearning(category : LearningCategory,  title : string, description? : string, relativeFiles? : number[]) {
        await api.postWithCallback(
          `/api/learning`,
          {category, title, description, relativeFiles},
          ()=>{ appNavigator.goBack(); }
        );
    }


    /////////////////////////////////////////////////////////////////////////////
    // Functions
    async function onWriteLearningClick() {
        if( category === LearningCategory.none )
        return appToast.error("카테고리를 선택해 주십시오.");


        const titleParam = title.trim();
        if( titleParam.length === 0 )
            return appToast.error("제목을 입력해 주십시오.");

        const encodeResult = await refTextEditor.current?.encodeAndConfirmTmpImage();
        const description = encodeResult?.encodedText;
        
        await api_writeLearning(category, titleParam, description, encodeResult?.relativeFiles);
    }


    /////////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="learningWritePage-20240716">
            <Typography variant="h3" mb={4}>학습 글 작성</Typography>
            
            <FormControl fullWidth>
                <InputLabel id="category-select-label">카테고리</InputLabel>
                <Select
                    labelId="category-select-label"
                    id="category-select"
                    value={category}
                    label="카테고리"
                    onChange={e=>setCategory(e.target.value as LearningCategory)}
                >
                    <MenuItem value={LearningCategory.fundamental}>fundamental</MenuItem>
                    <MenuItem value={LearningCategory.practice}>practice</MenuItem>
                    <MenuItem value={LearningCategory.example}>example</MenuItem>
                    <MenuItem value={LearningCategory.tip}>tip</MenuItem>
                    <MenuItem value={LearningCategory.tool}>tool</MenuItem>
                </Select>
            </FormControl>


            


            <Typography className="title-label">제목 *</Typography>
            <TextField className="title-text" value={title} onChange={(e)=>setTitle(e.target.value)  }/>

            <Typography className="description-label">내용 *</Typography>
            <SHSTextEditor refEditor={refTextEditor} />
                        
            <Button 
                className="btn-write" 
                variant="outlined"                 
                onClick={onWriteLearningClick}>글쓰기</Button>
        </Stack>
    );
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default ExerciseWritePage;
  